import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Motion_Detection/Setup/YoutubeVideoCardSetup';
import PrimaryBox from 'components/Motion_Detection/Setup/720p_Series/PrimaryBox';
import NavButtons from 'components/Motion_Detection/Setup/NavButtons';
import ForumBox from 'components/Motion_Detection/Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Motion Detection",
  "path": "/Motion_Detection/Setup/720p_Series/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setup the Motion Detection Function",
  "image": "./MD_SearchThumb_MDSetup_720p.png",
  "social": "/images/Search/MD_SearchThumb_MDSetup_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Motion-Detection-Setup_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Motion Detection' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setup the Motion Detection Function' image='/images/Search/MD_SearchThumb_MDSetup_720p.png' twitter='/images/Search/MD_SearchThumb_MDSetup_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Einrichten/720p_Serie/' locationFR='/fr/Motion_Detection/Setup/720p_Series/' crumbLabel="Setup" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h1>
    <h2 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h2>
    <h2 {...{
      "id": "instar-720p-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-720p-camera-series",
        "aria-label": "instar 720p camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR 720p Camera Series`}</h2>
    <p>{`To activate the motion detection, open the web interface of your INSTAR HD IP camera and click on the menu `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Areas/"
      }}>{`Alarm / Areas`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/946bb101354fa8fbe6bc7222284a2cb4/29d31/Bewegungserkennung_Alarm_HD_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHT6pBzUf/EABsQAAEFAQEAAAAAAAAAAAAAAAIAAQMTIRES/9oACAEBAAEFAjx/T9YskjE1UCrFf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABITEREjL/2gAIAQEABj8CWILJFsjko//EABwQAAICAgMAAAAAAAAAAAAAAAERACEQQTFR8P/aAAgBAQABPyG5Oza3CEyDo9wS7R9YuLnscL//2gAMAwEAAgADAAAAENPv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWGR0f/aAAgBAQABPxB7kXTtfcDFUrkenI1AKLWNpsorlb4Z0Up2rfYAAHM5Z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/946bb101354fa8fbe6bc7222284a2cb4/e4706/Bewegungserkennung_Alarm_HD_01_EN.avif 230w", "/en/static/946bb101354fa8fbe6bc7222284a2cb4/d1af7/Bewegungserkennung_Alarm_HD_01_EN.avif 460w", "/en/static/946bb101354fa8fbe6bc7222284a2cb4/70e80/Bewegungserkennung_Alarm_HD_01_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/946bb101354fa8fbe6bc7222284a2cb4/a0b58/Bewegungserkennung_Alarm_HD_01_EN.webp 230w", "/en/static/946bb101354fa8fbe6bc7222284a2cb4/bc10c/Bewegungserkennung_Alarm_HD_01_EN.webp 460w", "/en/static/946bb101354fa8fbe6bc7222284a2cb4/426ac/Bewegungserkennung_Alarm_HD_01_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/946bb101354fa8fbe6bc7222284a2cb4/e83b4/Bewegungserkennung_Alarm_HD_01_EN.jpg 230w", "/en/static/946bb101354fa8fbe6bc7222284a2cb4/e41a8/Bewegungserkennung_Alarm_HD_01_EN.jpg 460w", "/en/static/946bb101354fa8fbe6bc7222284a2cb4/29d31/Bewegungserkennung_Alarm_HD_01_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/946bb101354fa8fbe6bc7222284a2cb4/29d31/Bewegungserkennung_Alarm_HD_01_EN.jpg",
              "alt": "Alarm Area Setup for the 720p Camera Series",
              "title": "Alarm Area Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can select which area of the video the motion detection should be armed in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/HD_Series/Alarm/Areas/"
      }}>{`Areas Overlay`}</a>{`. Therefore, please select the Area on the right side for which you want to do the settings. Next please click `}<strong parentName="p">{`Active`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49c549f5645d7b48271d7dcf85b7b257/29d31/Bewegungserkennung_Alarm_HD_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEAf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAbtpYQcP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAECEx/9oACAEBAAEFAkoO5AMQr//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAQEAAwEBAAAAAAAAAAAAAAEAETFRIWH/2gAIAQEAAT8hwTjVqscED6C+BIcv/9oADAMBAAIAAwAAABCDD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQACAgMAAAAAAAAAAAAAAAEAIRExQVFh/9oACAEBAAE/EE9wUpjMAar47ggKK+EKKm9kuzceTMKa6n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49c549f5645d7b48271d7dcf85b7b257/e4706/Bewegungserkennung_Alarm_HD_02_EN.avif 230w", "/en/static/49c549f5645d7b48271d7dcf85b7b257/d1af7/Bewegungserkennung_Alarm_HD_02_EN.avif 460w", "/en/static/49c549f5645d7b48271d7dcf85b7b257/70e80/Bewegungserkennung_Alarm_HD_02_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49c549f5645d7b48271d7dcf85b7b257/a0b58/Bewegungserkennung_Alarm_HD_02_EN.webp 230w", "/en/static/49c549f5645d7b48271d7dcf85b7b257/bc10c/Bewegungserkennung_Alarm_HD_02_EN.webp 460w", "/en/static/49c549f5645d7b48271d7dcf85b7b257/426ac/Bewegungserkennung_Alarm_HD_02_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49c549f5645d7b48271d7dcf85b7b257/e83b4/Bewegungserkennung_Alarm_HD_02_EN.jpg 230w", "/en/static/49c549f5645d7b48271d7dcf85b7b257/e41a8/Bewegungserkennung_Alarm_HD_02_EN.jpg 460w", "/en/static/49c549f5645d7b48271d7dcf85b7b257/29d31/Bewegungserkennung_Alarm_HD_02_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49c549f5645d7b48271d7dcf85b7b257/29d31/Bewegungserkennung_Alarm_HD_02_EN.jpg",
              "alt": "Alarm Area Setup for the 720p Camera Series",
              "title": "Alarm Area Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will see that the area which you have set to `}<strong parentName="p">{`Active`}</strong>{` is now showing a colour. Also on top the number for the area activated will light up in red to symbolize that this area is active. By simply clicking into the picture and dragging your mouse you can choose the area for motion detection. The motion detection will be armed in the area you have selected. In case you want to select the whole area you can click "Full Area" on the right side in order to activate the motion detection over the whole picture. Now you can use the slide bar to set the sensitivity for the motion.`}</p>
    <p><strong parentName="p">{`1 = Insensitive`}</strong>{` (almost no motion gets detected) `}<strong parentName="p">{`100 = Very Sensitive`}</strong>{` (almost every motion will be recorded)`}</p>
    <p>{`We recommend you so leave the motion sensitivity at 50 and slowly test which setting will deliver the best result.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/557bc997e9393240fecb24427495406e/29d31/Bewegungserkennung_Alarm_HD_03_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAUdqhloT/8QAGxAAAgEFAAAAAAAAAAAAAAAAAQISAAMQITH/2gAIAQEAAQUCuadCZDkVNQXH/8QAFhEBAQEAAAAAAAAAAAAAAAAAERAh/9oACAEDAQE/AXCf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQITL/2gAIAQEABj8CYbWhOSv/xAAbEAEBAQACAwAAAAAAAAAAAAABABEhUWGh8f/aAAgBAQABPyE5xcziJ6m2UqEFFT5L4Nh1f//aAAwDAQACAAMAAAAQjA//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAYo//EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QTbj/xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMUFhkf/aAAgBAQABPxBTs9nDmyzGj7L88gNFS0omxPaKwFEBONcjlj5P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/557bc997e9393240fecb24427495406e/e4706/Bewegungserkennung_Alarm_HD_03_EN.avif 230w", "/en/static/557bc997e9393240fecb24427495406e/d1af7/Bewegungserkennung_Alarm_HD_03_EN.avif 460w", "/en/static/557bc997e9393240fecb24427495406e/70e80/Bewegungserkennung_Alarm_HD_03_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/557bc997e9393240fecb24427495406e/a0b58/Bewegungserkennung_Alarm_HD_03_EN.webp 230w", "/en/static/557bc997e9393240fecb24427495406e/bc10c/Bewegungserkennung_Alarm_HD_03_EN.webp 460w", "/en/static/557bc997e9393240fecb24427495406e/426ac/Bewegungserkennung_Alarm_HD_03_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/557bc997e9393240fecb24427495406e/e83b4/Bewegungserkennung_Alarm_HD_03_EN.jpg 230w", "/en/static/557bc997e9393240fecb24427495406e/e41a8/Bewegungserkennung_Alarm_HD_03_EN.jpg 460w", "/en/static/557bc997e9393240fecb24427495406e/29d31/Bewegungserkennung_Alarm_HD_03_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/557bc997e9393240fecb24427495406e/29d31/Bewegungserkennung_Alarm_HD_03_EN.jpg",
              "alt": "Alarm Area Setup for the 720p Camera Series",
              "title": "Alarm Area Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Repeat the above steps in case you want to set more than one area. Each area will be given a different colour:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Area 1`}</strong>{` = red`}</li>
      <li parentName="ul"><strong parentName="li">{`Area 2`}</strong>{` = blue`}</li>
      <li parentName="ul"><strong parentName="li">{`Area 3`}</strong>{` = green`}</li>
      <li parentName="ul"><strong parentName="li">{`Area 4`}</strong>{` = yellow`}</li>
    </ul>
    <p><strong parentName="p">{`Submit`}</strong>{` the settings in order to save all settings in the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3b4af40ee9fe48ade305803946c879b6/29d31/Bewegungserkennung_Alarm_HD_04_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAY7DlE6j/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAIREgEDIjP/2gAIAQEAAQUCf16TYsVwilVIP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAECAQE/Aaf/xAAaEAEBAAIDAAAAAAAAAAAAAAABABAREiEx/9oACAEBAAY/AkGO4t8Td4Y//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARMSFRYZH/2gAIAQEAAT8h52kAFuPuWJRkR0vlUu35RBgT/9oADAMBAAIAAwAAABCIz//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAXEn//xAAXEQEBAQEAAAAAAAAAAAAAAAABERBB/9oACAECAQE/EBS+TP/EABwQAQEBAAIDAQAAAAAAAAAAAAERADFBIVFxkf/aAAgBAQABPxCHqACsBnrOTG0FGfd20h60to5ZX91ArThT40IEBwG//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b4af40ee9fe48ade305803946c879b6/e4706/Bewegungserkennung_Alarm_HD_04_EN.avif 230w", "/en/static/3b4af40ee9fe48ade305803946c879b6/d1af7/Bewegungserkennung_Alarm_HD_04_EN.avif 460w", "/en/static/3b4af40ee9fe48ade305803946c879b6/70e80/Bewegungserkennung_Alarm_HD_04_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3b4af40ee9fe48ade305803946c879b6/a0b58/Bewegungserkennung_Alarm_HD_04_EN.webp 230w", "/en/static/3b4af40ee9fe48ade305803946c879b6/bc10c/Bewegungserkennung_Alarm_HD_04_EN.webp 460w", "/en/static/3b4af40ee9fe48ade305803946c879b6/426ac/Bewegungserkennung_Alarm_HD_04_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b4af40ee9fe48ade305803946c879b6/e83b4/Bewegungserkennung_Alarm_HD_04_EN.jpg 230w", "/en/static/3b4af40ee9fe48ade305803946c879b6/e41a8/Bewegungserkennung_Alarm_HD_04_EN.jpg 460w", "/en/static/3b4af40ee9fe48ade305803946c879b6/29d31/Bewegungserkennung_Alarm_HD_04_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3b4af40ee9fe48ade305803946c879b6/29d31/Bewegungserkennung_Alarm_HD_04_EN.jpg",
              "alt": "Alarm Area Setup for the 720p Camera Series",
              "title": "Alarm Area Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will need to select an Action so that the camera knows what it should do in case of an alarm. Please open the menu `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/HD_Series/Alarm/Actions/"
      }}>{`Alarm / Actions`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/29d31/Bewegungserkennung_Alarm_HD_05_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHRdQw5qP/EABsQAAEFAQEAAAAAAAAAAAAAAAIAAQMTIRES/9oACAEBAAEFApMf0/WLJIwJVAqxX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAQADAQAAAAAAAAAAAAAAAAABETEh/9oACAEBAAY/AorjXUXDGP/EAB0QAAICAQUAAAAAAAAAAAAAAAERAEEhMVFhsfD/2gAIAQEAAT8h5cbVwhMg8HeCW0Ok9aZUr7zyM//aAAwDAQACAAMAAAAQwy//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUWGR0f/aAAgBAQABPxArHTRZte5gYqk5Hx1jUAorlVQui7enPAx3K/cgAahOWf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/e4706/Bewegungserkennung_Alarm_HD_05_EN.avif 230w", "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/d1af7/Bewegungserkennung_Alarm_HD_05_EN.avif 460w", "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/70e80/Bewegungserkennung_Alarm_HD_05_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/a0b58/Bewegungserkennung_Alarm_HD_05_EN.webp 230w", "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/bc10c/Bewegungserkennung_Alarm_HD_05_EN.webp 460w", "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/426ac/Bewegungserkennung_Alarm_HD_05_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/e83b4/Bewegungserkennung_Alarm_HD_05_EN.jpg 230w", "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/e41a8/Bewegungserkennung_Alarm_HD_05_EN.jpg 460w", "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/29d31/Bewegungserkennung_Alarm_HD_05_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e2bf1db0a6f77abbc1dfe57dea75846b/29d31/Bewegungserkennung_Alarm_HD_05_EN.jpg",
              "alt": "Alarm Actions Setup for the 720p Camera Series",
              "title": "Alarm Actions Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can now set if the camera should send you an email with snapshots in the attachment or if the pictures should be saved to an SD card or an FTP server. In order to save pictures or videos to an SD card please make sure an SD card in inserted in your camera.`}</p>
    <p>{`Finally, please `}<strong parentName="p">{`Submit`}</strong>{` the settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "470px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d1c96f0973645427814f13770d344c05/747d8/Bewegungserkennung_Alarm_HD_06_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeygwzg44y2P/8QAGhABAAEFAAAAAAAAAAAAAAAAASAAAhAREv/aAAgBAQABBQLDaMNFckf/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAXEAEAAwAAAAAAAAAAAAAAAAABECEw/9oACAEBAAY/AosMv//EAB0QAAIBBAMAAAAAAAAAAAAAAAABERAhQWEgMZH/2gAIAQEAAT8hi9G8tPaGr58EopNg0iSXXD//2gAMAwEAAgADAAAAEPAFfP/EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAwEBPxA2n//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExUWEQkeH/2gAIAQEAAT8QGXtrjMCz5K4kUILUFSvsgDBU5mw5PcCut+2C0KIaPP8A/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d1c96f0973645427814f13770d344c05/e4706/Bewegungserkennung_Alarm_HD_06_EN.avif 230w", "/en/static/d1c96f0973645427814f13770d344c05/d1af7/Bewegungserkennung_Alarm_HD_06_EN.avif 460w", "/en/static/d1c96f0973645427814f13770d344c05/d5421/Bewegungserkennung_Alarm_HD_06_EN.avif 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d1c96f0973645427814f13770d344c05/a0b58/Bewegungserkennung_Alarm_HD_06_EN.webp 230w", "/en/static/d1c96f0973645427814f13770d344c05/bc10c/Bewegungserkennung_Alarm_HD_06_EN.webp 460w", "/en/static/d1c96f0973645427814f13770d344c05/4424c/Bewegungserkennung_Alarm_HD_06_EN.webp 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d1c96f0973645427814f13770d344c05/e83b4/Bewegungserkennung_Alarm_HD_06_EN.jpg 230w", "/en/static/d1c96f0973645427814f13770d344c05/e41a8/Bewegungserkennung_Alarm_HD_06_EN.jpg 460w", "/en/static/d1c96f0973645427814f13770d344c05/747d8/Bewegungserkennung_Alarm_HD_06_EN.jpg 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d1c96f0973645427814f13770d344c05/747d8/Bewegungserkennung_Alarm_HD_06_EN.jpg",
              "alt": "Alarm Actions Setup for the 720p Camera Series",
              "title": "Alarm Actions Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      